// main.js v2.2.1 date 19.03.24
(function ($) {
    "use strict";
    
    /*****************************
    * Commons Variables
    *****************************/
    //var $window = $(window),
    //    $body = $('body');

    var sitename = (location.protocol || 'https:') + '//' + (location.hostname || 'www.arnebia.ru');
		
		
    /************************************************
     * Product Slider - Style: Default [4 Grid, 1 Rows]
     ***********************************************/

	$('.product-top5order').each(function(){
		var objSliderGoods = $(this);
		var slGoodsId = objSliderGoods.attr('id') || '';

		if (slGoodsId) {
			var param = '';
			var add_url = '';
			
			param = objSliderGoods.attr('data-tn') || '';
			if (param){add_url += '&disOrdTn='+param;}
			
			param = objSliderGoods.attr('data-type') || '';
			if (param){add_url += '&disOrdType='+param;}
			
			param = objSliderGoods.attr('data-data') || '';
			if (param){add_url += '&disOrdData='+param;}
			
			param = objSliderGoods.attr('data-visable') || '';
			if (param){add_url += '&disOrdAll='+param;}
			
			param = objSliderGoods.attr('data-limit') || '';
			if (param){add_url += '&disOrdLimit='+param;}
			
			param = objSliderGoods.attr('data-esc') || '';
			if (param){add_url += '&disOrdEsc='+param;}

			param = objSliderGoods.attr('data-menu') || '';
			if (param){add_url += '&disOrdMenu='+param;}

			param = objSliderGoods.attr('data-good') || '';
			if (param){add_url += '&disOrdGood='+param;}

			param = objSliderGoods.attr('data-basket') || '';
			if (param){add_url += '&basket='+param;}
			
			param = objSliderGoods.attr('data-back') || '';
			if (param){add_url += '&back='+param;}

			kmkCMS.loadXMLDoc( sitename + '/cgi-bin/top5order.cgi?disOrdCase='+slGoodsId+add_url,slGoodsId,slGoodsId+'_loader',
			product_ui_setting);
		} else {
            product_ui_setting(objSliderGoods[0]);
        }
    });
    
	$('.product-srvmarket').each(function(){
		var objSliderGoods = $(this);
        if (objSliderGoods[0].querySelector('a[name^="good_show_"]')) return; //already done
        var idGoods = objSliderGoods.attr('data-good') || '';

		if (idGoods) {
			var param = '';
			var add_url = '&htmhead=0';
			
			param = objSliderGoods.attr('data-param') || '';
			if (param){add_url += '&param='+param;}

			param = objSliderGoods.attr('data-tn') || '';
			if (param){add_url += '&tn='+param;}
			
			param = objSliderGoods.attr('data-tpltmp') || '';
			if (param){add_url += '&tpltmp='+param;}
                
            param = objSliderGoods.attr('data-type') || '';
            if (param){add_url += '&type='+param;}
			
			param = objSliderGoods.attr('data-visable') || '';
			if (param){add_url += '&vis='+param;}

			param = objSliderGoods.attr('data-bonus') || '';
			if (param){add_url += '&bonus='+param;}
			
			param = objSliderGoods.attr('data-basket') || '';
			if (param){add_url += '&basket='+param;}
			
			param = objSliderGoods.attr('data-back') || '';
			if (param){add_url += '&back='+param;}
            
			kmkCMS.loadXMLDoc( sitename + '/cgi-bin/srv_market_show.cgi?id_good='+idGoods+add_url,objSliderGoods[0],null,
			product_ui_setting);
		}
	});
    
    function product_ui_setting(slGoodsId){
        if (typeof window.MKTbasket != 'undefined') new MKTbasket(slGoodsId);
        product_default_slider(slGoodsId);
    }
	
	function product_default_slider(slGoodsId) {
		if($(slGoodsId).hasClass('product-default-slider-4grid-1rows')) {
			$(slGoodsId).slick({
				infinite: true,
				dots: true,
				slidesToShow: 4,
				slidesToScroll: 4,
				arrows: true,
				centerMode:false,
				responsive: [
					{
					  breakpoint: 1137,
					  settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					  }
					},
					{
					  breakpoint: 580,
					  settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					  }
					},
					{
					  breakpoint: 374,
					  settings: {
						dots: false,
						slidesToShow: 1,
						slidesToScroll: 1,
					  }
					}
				]
			});
		}
		kmkCMS.scrollToAnchor(slGoodsId);
	}
    /************************************************
     * Periodic SRV Slider
     ***********************************************/    
    $('.periodic-feed').each(function(){
        var objSliderPer = $(this);
        var slPerId = objSliderPer.attr('data-id') || '';

        if (slPerId) {
            var param = '';
            var add_url = '';

            param = objSliderPer.attr('data-tn') || '';
            if (param){add_url += '&tn='+param;}

            param = objSliderPer.attr('data-type') || '';
            if (param){add_url += '&type='+param;}
                
            kmkCMS.loadXMLDoc( sitename + '/cgi-bin/srv_periodic_show.cgi?id_per='+slPerId+add_url,objSliderPer[0],null,
            periodic_feed_default_slider);
        }
    });
	function periodic_feed_default_slider(slPerId) {
        kmkCMS.scrollToAnchor(slPerId);
    }
    
    $('.menu-srvparent').each(function(){
        var objMenu = $(this);
        if(objMenu){
			var param = '';
			param = objMenu.attr('data-menu') || '';
            if(param) {
                var param_json = JSON.parse(param);
                new MenuParent(objMenu[0],param_json);
            }
        }
    });    

    $('.floatnews').each(function(){
        var objNews = $(this);
		var idNews = objNews.attr('data-news') || '';

        if(objNews && idNews){
			var param = '';
			var add_url = '';
			
			param = objNews.attr('data-param') || '';
			if (param){add_url += '&param='+param;}
            
			kmkCMS.loadXMLDoc( sitename + '/cgi-bin/srv_news_show.cgi?id_news='+idNews+add_url,objNews[0]);
        }
    });
    
	$('.dnl-count').each(function(){
        var objDnl = $(this);
        var objDnlcount = $(objDnl).find('span').first();
        if(objDnl && objDnlcount){
			var param = '';
			var add_url = '';
			
			param = objDnl.attr('data-dnl') || '';
			if (param){add_url += '&id_dnl='+param;}
            
			kmkCMS.loadXMLDoc( sitename + '/cgi-bin/download.cgi?mode=count'+add_url,objDnlcount[0]);
        }
    });
    
	$('.dnl-top5dnl').each(function(){
        var objDnlRng = $(this);
        var param = '';
        var add_url;
        var url_param = [];

        param = objDnlRng.attr('data-data') || '';
        if(param) url_param.push('disDnlData='+param);

        param = objDnlRng.attr('data-limit') || '';
        if(param) url_param.push('disDnlLimit='+param);
        
        param = objDnlRng.attr('data-esc') || '';
        if(param) url_param.push('disDnlEsc='+param);

        param = objDnlRng.attr('data-hst') || '';
        if(param) url_param.push('disDnlHst='+param);
        
        add_url = url_param.join('&');
        
        kmkCMS.loadXMLDoc( sitename + '/cgi-bin/top5dnl.cgi?'+add_url,objDnlRng[0]);
    });
    
    /*******************
     * Basket (count of goods)
     *******************/
    if (typeof window.MKTbasket != 'undefined') new MKTbasket();

    /****************************
    * Set Telephone Mask
    *****************************/
    $('input[name=NewBsk_TelPh]').each(function(){
        $(this).mask("8(999) 999-9999");
    });
    $('input[name=NewReg_TelPh]').each(function(){
        $.mask.definitions['x']='[0-689]';
        $(this).mask("8(x99) 999-9999");
    });
    
    
    /*****************************
    * DaData Suggestions Activate
    *****************************/
	if ($('form[name=OrderSaveUnReg]').length){
        if ( typeof(dadata) !== 'undefined'){
            dadata('NewBsk_chosenPost','NewBsk_chosenCity','NewBsk_addresPost');
        }
    }
	if ($('form[name=NewAddresForm]').length){
        if ( typeof(dadata) !== 'undefined'){
            dadata('NewReg_Index','NewReg_City','NewReg_Addres');
        }
    }
	if ($('form[name=NewOrganisationForm]').length){
        if ( typeof(dadata_org) !== 'undefined'){
            dadata_org('NewReg_Org');
        }
        if ( typeof(dadata_bank) !== 'undefined'){
            dadata_bank('NewReg_Org_BankName');
        }
    }
	if ($('form[name=OrganizationPriceList]').length){
        if ( typeof(dadata_organization) !== 'undefined'){
            dadata_organization('OrganizationName','dadateINN');
        }
    }
})(jQuery);

    
/*****************************
* Confirm Cookie Activate
*****************************/
if ( typeof(CookieBar) !== 'undefined'){
    new CookieBar({
        selector: '#cookieBar',
        message: 'Наш сайт использует cookies. Продолжая им пользоваться, вы соглашаетесь на обработку персональных данных в соответствии с <a href="https://www.arnebia.ru/cgi-bin/srv_news_show.cgi?id_news=2345" onclick="srv_show.show_on(this.href); return false; return false;">Положением о персональных данных</a>',
        button: 'Согласиться',
        theme: '#666666',
        expire: "1024",
        storage:"local",
    });
}

/*****************************
* Modal windows activate
*****************************/
var srv_show;
if (typeof (SRV_Show) !== 'undefined') {
	srv_show = new SRV_Show({
		scroll: 'scroll',
		centered: 'centered',
	});
}

/*****************************
* Download function activate
*****************************/
function setDownLoad(url) {
    srv_show.show_on(url + '&tn=panel');
}

/*****************************
* Promocode modal window activate
*****************************/
var mkt_promo;
if (typeof (MKTpromo) !== 'undefined') {
	mkt_promo = new MKTpromo({
		template: '<li>Промокод: <span class="news_marker">{name}</span> скидка <span class="news_marker_red">{kf}%</span></li>',
	});
}
function setPromoGood(id_good) {
    mkt_promo.getPromo(id_good,_renderPromo);
    function _renderPromo(arrPromo) {
        let promo_html;
        if(arrPromo !== 'undefined' && arrPromo.length) {
            promo_html = '<h3 class="mb-1">Товар участвует в АКЦИИ</h3><p>Для получения скидки на этот товар воспользуйтесь промокодом</p><ul class="list__marker--check">'+arrPromo.join('')+'</ul>';
        }else{
            promo_html = '<h3 class="mb-1">АКЦИИ не найдены</h3><p>Для этого товара нет действующих промокодов.</p>';
        }
        srv_show.show_on(promo_html);
    }
}


/*****************************
* Печать квитанции (вывод метода в общий доступ)
*****************************/
window.prn = kmkCMS.prn;